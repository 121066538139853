// extracted by mini-css-extract-plugin
export var about = "main-module--about--z831M";
export var certandsub = "main-module--certandsub--AKkre";
export var container = "main-module--container--WRqSx";
export var description = "main-module--description--uERcv";
export var footer = "main-module--footer--mv2vT";
export var icon = "main-module--icon--eqfUo";
export var info = "main-module--info--3Xmp2";
export var linkcert = "main-module--linkcert--v2UNk";
export var linksubs = "main-module--linksubs--ZbPV5";
export var servicestitle = "main-module--servicestitle--gaoUC";
export var shop = "main-module--shop--gptkT";
export var stock = "main-module--stock--vQwek";
export var svg = "main-module--svg--DSoNz";
export var title = "main-module--title--dAd9l";
export var titledesc = "main-module--titledesc--5AkXG";
export var vector = "main-module--vector--SmO7K";