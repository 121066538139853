import { Link } from "gatsby";
import React from "react";
import {
  container,
  title,
  description,
  stock,
  servicestitle,
  footer,
  about,
  titledesc,
  info,
  vector,
  certandsub,
  icon,
  linksubs,
  linkcert,
  svg,
  shop,
} from "../styles/pages/main.module.scss";
import { variantPages } from "../animations/pageTransition";
import { motion } from "framer-motion";
import MetaTag from "../components/metaTag";
import { indexSEO } from "../components/data/seo";
import Services from "../components/services";
import { BsFillGiftFill, BsArrowRight } from "react-icons/bs";
import { MdCleanHands } from "react-icons/md";

// markup
const IndexPage = () => {
  const LinkMotion = motion(Link);
  return (
    <>
      <MetaTag data={indexSEO} />
      <motion.section
        variants={variantPages}
        initial="initial"
        exit="exit"
        animate="animate"
        className={container}
      >
        <h1 className={title}>Cleanteam</h1>
        <h2 className={description}>
          Уборка квартир и химчистка
          <br />
          любой сложности в Красноярске
        </h2>
        <p className={stock}>
          Закажите клининг по акции! При заказе любого типа помещения - <br />
          скидка до 30% на услуги химчистки мягкой мебели
        </p>
        <div className={certandsub}>
          <LinkMotion
            to="/subscription/"
            className={linksubs}
            style={{
              background: "linear-gradient(180deg, #FFC32E 0%, #EBA906 100%)",
            }}
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ delay: 0.1, duration: 0.4 }}
          >
            <motion.div
              initial={{ scale: 0.925, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ delay: 0.15, duration: 0.4 }}
              className={icon}
            >
              <MdCleanHands className={svg} />
            </motion.div>
            <h3 className={title}>
              Абонементы на
              <br /> ежедневную уборку
            </h3>
          </LinkMotion>
          <LinkMotion
            to="/certificates/"
            className={linkcert}
            style={{
              background: "linear-gradient(180deg, #FF8D40 0%, #DF6614 100%)",
            }}
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ delay: 0.175, duration: 0.48 }}
          >
            <motion.div
              initial={{ scale: 0.925, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ delay: 0.2, duration: 0.4 }}
              className={icon}
            >
              <BsFillGiftFill className={svg} />
            </motion.div>
            <h3 className={title}>
              Подарочные сертификаты
              <br /> от 1000Р
            </h3>
          </LinkMotion>
        </div>
        <h3 className={servicestitle}>Наши услуги:</h3>
        <Services />
        <a href="https://cleaning-expert24.ru" className={shop}>
          Наш магазин бытовой химии
          <BsArrowRight />
        </a>
        <div className={footer}>
          <Link to="/about" className={about}>
            <h3 className={title}>О нас</h3>
            <p className={titledesc}>О Команде Cleanteam</p>
            <p className={description}>
              Как мы работаем?
              <br />С кем мы сотрудничаем?
            </p>
            <img src="/cartvector/2.svg" alt="фон вектор" className={vector} />
          </Link>
          <Link to="/info" className={info}>
            <h3 className={title}>Полезная информация</h3>
            <p>Часто задаваемые вопросы</p>
            <img src="/cartvector/2.svg" alt="фон вектор" className={vector} />
          </Link>
        </div>
      </motion.section>
    </>
  );
};

export default IndexPage;
